// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/no-use-before-define */

import * as React from 'react';

import styled from '@emotion/styled';

import { BREAKPOINTS, getColorTint, unit } from '@zego/theme';

import View from '@zego/components/atoms/View';

import Tick from '@zego/static/svgs/Tick.svg';

interface BenefitProps {
  children?: React.ReactNode;
  emphasis: React.ReactNode;
  emphasisFontSize: number;
}

export default function Benefit({
  children,
  emphasis,
  emphasisFontSize,
}: BenefitProps): JSX.Element {
  return (
    <ListItem>
      <img
        src={Tick}
        alt=""
        css={{
          marginRight: unit(2),
          minWidth: unit(3),
          width: unit(3),
        }}
      />
      <View>
        {emphasis && (
          <Emphasis css={{ fontSize: emphasisFontSize }}>{emphasis}.</Emphasis>
        )}
        {children && (
          <p
            css={{
              display: 'inline',
              lineHeight: `${unit(3)}px`,
              margin: 0,
              fontSize: 16,
            }}
          >
            {children}
          </p>
        )}
      </View>
    </ListItem>
  );
}

/**
 * Styled components
 */

const ListItem = styled('li')({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'row',
  marginBottom: unit(2),
  color: getColorTint('grey', 8),
  fontSize: 16,
  [BREAKPOINTS.medium]: {
    fontSize: 18,
  },
});

const Emphasis = styled('h3')({
  display: 'inline',
  lineHeight: `${unit(3)}px`,
  margin: 0,
  fontSize: '1em',
  fontWeight: 'bold',
  marginRight: unit(1),
  [BREAKPOINTS.medium]: {
    lineHeight: 1,
  },
});
