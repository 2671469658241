// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/no-use-before-define */

import * as React from 'react';

import styled from '@emotion/styled';

import { BREAKPOINTS, unit } from '@zego/theme';

import Benefit from '@zego/components/atoms/Benefit';
import H2 from '@zego/components/atoms/H2';
import View from '@zego/components/atoms/View';

interface CheckListProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  descriptionFontSize?: number;
  emphasisFontSize?: number;
  items: Partial<{ title: string; explanation: string }>[];
}

export default function CheckList({
  title,
  description,
  descriptionFontSize = 16,
  emphasisFontSize = 18,
  items,
  ...rest
}: CheckListProps): JSX.Element {
  return (
    <View {...rest}>
      <Title>{title}</Title>
      {description && (
        <p css={{ fontSize: descriptionFontSize }}>{description}</p>
      )}
      <ul>
        {items.map(item => (
          <Benefit
            key={item.title}
            emphasis={item.title}
            emphasisFontSize={emphasisFontSize}
          >
            {item.explanation}
          </Benefit>
        ))}
      </ul>
    </View>
  );
}

/**
 * Styled components
 */

const Title = styled(H2)({
  fontSize: 30,
  marginBottom: unit(4.5),
  fontWeight: 600,
  letterSpacing: 'unset',
  [BREAKPOINTS.medium]: { fontSize: 40 },
});
